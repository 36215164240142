import React from "react";
import "./Requests.css";
import Title from "./Title";
import Navbar from "./Navbar";
import axios from "axios";
import {
  JsonView,
  allExpanded,
  collapseAllNested,
  defaultStyles,
} from "react-json-view-lite";

const LOGGER_BASE_URL = process.env.REACT_APP_LOGGER_BASE_URL;
const CENTRAL_API = process.env.REACT_APP_CENTRAL_API;

function Requests() {
  const urlRef = React.useRef<HTMLInputElement>(null);

  const [requests, serRequests] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const readData = async () => {
    serRequests([]);

    const url = urlRef.current?.value;

    try {
      setLoading(true);
      const response = await axios.post(
        `${CENTRAL_API}/request-logger/search`,
        {
          url,
        }
      );
      const data = await response.data;
      serRequests(data);
      console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="requests">
      <Title title="Anfragen 📥" />

      <div className="requests__input">
        <button onClick={readData}>Anfragen anzeigen</button>
        <input type="text" ref={urlRef} placeholder="URL was gesendet wurde" />
      </div>

      {loading && <p>Lade Anfragen...</p>}
      {requests.length > 0 && (
        <JsonView
          data={requests}
          shouldExpandNode={collapseAllNested}
          style={defaultStyles}
        />
      )}

      <Navbar />
    </div>
  );
}

export default Requests;
