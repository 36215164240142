import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Title from "./Title";
import "./GoodPrices.css";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { CarDto } from "./dto/car-dto";
import CarTile from "./CarTile";

// Extend CarDto with userId
type GoodPriceCarDto = CarDto & {
  userId: string;
};

const CENTRAL_API =
  process.env.REACT_APP_CENTRAL_API || "http://localhost:3000/api";

export const useGoodPricesCount = () => {
  // Check every 5 seconds for new good prices
  const [goodPricesCount, setGoodPricesCount] = React.useState(0);

  const fiveSeconds = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      fetchGoodPricesCount();
    }, fiveSeconds);

    return () => clearInterval(interval);
  }, []);

  const fetchGoodPricesCount = async () => {
    try {
      const response = await axios(`${CENTRAL_API}/good-prices/count`);

      console.log(response.data);
      setGoodPricesCount(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return goodPricesCount;
};

const useQueueCount = () => {
  // Check every 1 seconds for new good prices
  const [queueCount, setQueueCount] = React.useState(0);

  const oneSecond = 1000;

  useEffect(() => {
    const interval = setInterval(() => {
      fetchQueueCount();
    }, oneSecond);

    return () => clearInterval(interval);
  }, []);

  const fetchQueueCount = async () => {
    try {
      const response = await axios(`${CENTRAL_API}/good-prices/queue-count`);
      setQueueCount(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return queueCount;
};

function GoodPrices() {
  const queueCount = useQueueCount();
  const [goodPricesCount, setGoodPricesCount] = useState(0);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [goodPrices, setGoodPrices] = React.useState<GoodPriceCarDto[]>(
    [] as GoodPriceCarDto[]
  );

  useEffect(() => {
    fetchGoodPrices();
  }, []);

  const fetchGoodPrices = async () => {
    setIsLoaded(true);
    axios(`${CENTRAL_API}/good-prices/all`)
      .then((response) => {
        const cars: GoodPriceCarDto[] = response.data;

        const formatedCars = cars.map((car: GoodPriceCarDto) => {
          return {
            ...car,
            uuid: uuid(),
            isDuplicate: false,
            formated_time: getFormatedTime(car.createdAt),
          };
        });

        setGoodPrices(formatedCars);
        setIsLoaded(false);
        setGoodPricesCount(cars.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getFormatedTimeNumber(time: number): string {
    // If time is less than 10, add a zero in front
    return time < 10 ? `0${time}` : `${time}`;
  }

  function deleteCar(userId: string, externalCarId: string) {
    axios
      .delete(`${CENTRAL_API}/good-prices/${userId}/${externalCarId}`)
      .then(() => {
        const newCars = goodPrices.filter(
          (car: GoodPriceCarDto) =>
            car.userId !== userId && car.externalCarId !== externalCarId
        );
        setGoodPrices(newCars);
        setGoodPricesCount(newCars.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getFormatedTime(atTime: string): string {
    // 2023-12-02T06:52:34.458+00:00
    const date = new Date(atTime);

    // Convert date to 06.12.2023
    const day = getFormatedTimeNumber(date.getDate());
    const month = getFormatedTimeNumber(date.getMonth() + 1);
    const year = date.getFullYear();

    const dateFormated = `${day}.${month}.${year}`;

    const hours = getFormatedTimeNumber(date.getHours());
    const minutes = getFormatedTimeNumber(date.getMinutes());
    const seconds = getFormatedTimeNumber(date.getSeconds());

    return `${dateFormated} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <div className="goodPrices">
      <Title title="Gute Preise" />
      <Navbar />

      <div className="goodPrices__goodPriceCount">
        <p>{isLoaded ? "..." : goodPricesCount}</p>
      </div>
      <div className="goodPrices__queueCount">{queueCount} 🚙 .. zu prüfen</div>

      {goodPrices.map((car: GoodPriceCarDto) => (
        <>
          <p>User-ID: {car?.userId}</p>
          <CarTile
            key={car.uuid}
            car={car}
            isDuplicate={car.isDuplicate}
            deleteCar={() => deleteCar(car.userId, car.externalCarId)}
          />
        </>
      ))}
    </div>
  );
}

export default GoodPrices;
