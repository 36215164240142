import React from "react";
import Navbar from "./Navbar";
import Title from "./Title";
import "./Dataflow.css";
import {
  JsonView,
  allExpanded,
  collapseAllNested,
  defaultStyles,
} from "react-json-view-lite";

import axios from "axios";

const LOGGER_BASE_URL = process.env.REACT_APP_LOGGER_BASE_URL;

function Dataflow() {
  const carIdRef = React.useRef<HTMLInputElement>(null);

  const [carDetailsRequest, setCarDetailsRequest] = React.useState<any[]>([]);
  const [carDetailsRequestLoading, setCarDetailsRequestLoading] =
    React.useState<boolean>(false);
  const [carToJazmakki, setCarToJazmakki] = React.useState<any[]>([]);
  const [carToJazmakkiLoading, setCarToJazmakkiLoading] =
    React.useState<boolean>(false);
  const [detailsFromJazmakki, setDetailsFromJazmakki] = React.useState<any[]>(
    []
  );
  const [detailsFromJazmakkiLoading, setDetailsFromJazmakkiLoading] =
    React.useState<boolean>(false);
  const [emitedCarDetails, setEmitedCarDetails] = React.useState<any[]>([]);
  const [emitedCarDetailsLoading, setEmitedCarDetailsLoading] =
    React.useState<boolean>(false);

  const readData = async () => {
    // Clear data
    setCarDetailsRequest([]);
    setCarToJazmakki([]);
    setDetailsFromJazmakki([]);
    setEmitedCarDetails([]);

    // Vergleichslinkanfrage
    const externalCarId = carIdRef.current?.value;
    try {
      setCarDetailsRequestLoading(true);
      const response = await axios.get(
        `${LOGGER_BASE_URL}/car/car-details-request/${externalCarId}`
      );
      const data = await response.data;
      setCarDetailsRequest(data);
      setCarDetailsRequestLoading(false);
    } catch (error) {
      console.error(error);
      setCarDetailsRequestLoading(false);
    }

    // Gesendet an Jazmakki
    try {
      setCarToJazmakkiLoading(true);
      const response = await axios.get(
        `${LOGGER_BASE_URL}/car-to-jazmakki/${externalCarId}`
      );
      const data = await response.data;
      setCarToJazmakki(data);
      setCarToJazmakkiLoading(false);
    } catch (error) {
      console.error(error);
      setCarToJazmakkiLoading(false);
    }

    // Car details from jazmakki
    try {
      setDetailsFromJazmakkiLoading(true);
      const response = await axios.get(
        `${LOGGER_BASE_URL}/car-details/${externalCarId}`
      );
      const data = await response.data;
      setDetailsFromJazmakki(data);
      setDetailsFromJazmakkiLoading(false);
    } catch (error) {
      console.error(error);
      setDetailsFromJazmakkiLoading(false);
    }

    // Emited car details
    try {
      setEmitedCarDetailsLoading(true);
      const response = await axios.get(
        `${LOGGER_BASE_URL}/car-details/emited/${externalCarId}`
      );
      const data = await response.data;
      setEmitedCarDetails(data);
      setEmitedCarDetailsLoading(false);
    } catch (error) {
      console.error(error);
      setEmitedCarDetailsLoading(false);
    }
  };

  return (
    <div className="dataflow">
      <Title title="Datenfluss 📫" />

      <div className="dataflow__input">
        <button onClick={readData}>Daten lesen</button>
        <input type="text" ref={carIdRef} placeholder="External car ID" />
      </div>

      <p className="dataflow__description">Vergleichslinkanfrage</p>
      {carDetailsRequestLoading && <p className="dataflow__loading" />}

      {carDetailsRequest.length > 0 && (
        <JsonView
          data={carDetailsRequest}
          shouldExpandNode={collapseAllNested}
          style={defaultStyles}
        />
      )}
      <p className="dataflow__description">Gesendet an jazmakki.de</p>
      {carToJazmakkiLoading && <p className="dataflow__loading" />}

      {carToJazmakki.length > 0 && (
        <JsonView
          data={carToJazmakki}
          shouldExpandNode={collapseAllNested}
          style={defaultStyles}
        />
      )}

      <p className="dataflow__description">Daten vom jazmakki.de</p>
      {detailsFromJazmakkiLoading && <p className="dataflow__loading" />}

      {detailsFromJazmakki.length > 0 && (
        <JsonView
          data={detailsFromJazmakki}
          shouldExpandNode={collapseAllNested}
          style={defaultStyles}
        />
      )}
      <p className="dataflow__description">Gesendet an CarVorschau</p>
      {emitedCarDetailsLoading && <p className="dataflow__loading" />}

      {emitedCarDetails.length > 0 && (
        <JsonView
          data={emitedCarDetails}
          shouldExpandNode={collapseAllNested}
          style={defaultStyles}
        />
      )}
      <Navbar />
    </div>
  );
}

export default Dataflow;
