import "./Main.css";
import { useState, useEffect, useRef, useContext } from "react";
import Statistic from "./Statistic";
import { CarDto, CarFromSocketDto } from "./dto/car-dto";
import { FragmentFromSocketDto } from "./dto/fragment-dto";
import { v4 as uuid } from "uuid";
import CarTile from "./CarTile";
import Navbar from "./Navbar";
import Title from "./Title";
import { useAuth } from "./provider/AuthContext";
import { io } from "socket.io-client";

interface ICar {
  insertCar(cars: CarDto[]): CarDto[];
}
class CarFactory {
  static createCar(socketCar: CarDto, cars: CarDto[]): ICar {
    // Check if car already exists
    const car = cars.find(
      (car: CarDto) => car.externalCarId === socketCar.externalCarId
    );

    // It need to be done because the data comes twice. The Cardeluxe send the data twice to complete the data of the car.
    // Since we are clicking on the car too fast, the data is not complete on the first time when the server send the data
    if (car && car.source.includes("autoscout24")) {
      return new Autoscout24WithSameID(socketCar);
    }

    return new NewCar(socketCar);
  }
}

// Update the car with the same ID from autoscout24 after server send the second part of the data
class Autoscout24WithSameID implements ICar {
  carWithSameID: CarDto;

  constructor(car: CarDto) {
    this.carWithSameID = car;
    this.carWithSameID.uuid = uuid();
  }

  insertCar(cars: CarDto[]): CarDto[] {
    return cars.map((car: CarDto) => {
      if (car.externalCarId === this.carWithSameID.externalCarId) {
        return {
          ...car,
          city: this.carWithSameID.city,
          price_rating: this.carWithSameID.price_rating,
        };
      }
      return car;
    });
  }
}

class NewCar implements ICar {
  newCar: CarDto;

  constructor(car: CarDto) {
    this.newCar = car;
    this.newCar.uuid = uuid();
    this.newCar.formated_time = this.getFormatedTime();

    if (!this.newCar.city && this.newCar.source.includes("autoscout24")) {
      this.newCar.count_down = 65;
    }
  }

  isIdExists(newCar: CarDto, cars: CarDto[]): boolean {
    return cars.some(
      (car: CarDto) => car.externalCarId === newCar.externalCarId
    );
  }

  getFormatedTimeNumber = (time: number): string => {
    // If time is less than 10, add a zero in front
    return time < 10 ? `0${time}` : `${time}`;
  };

  getFormatedTime() {
    const date = new Date();
    const hours = this.getFormatedTimeNumber(date.getHours());
    const minutes = this.getFormatedTimeNumber(date.getMinutes());
    const seconds = this.getFormatedTimeNumber(date.getSeconds());

    return `${hours}:${minutes}:${seconds}`;
  }

  insertCar(cars: CarDto[]): CarDto[] {
    this.newCar.isDuplicate = this.isIdExists(this.newCar, cars);

    return [this.newCar, ...cars];
  }
}

export default function Main() {
  const { token, refreshToken } = useAuth();
  const defaultTitle = useRef(document.title);
  const [cars, setCars] = useState<CarDto[]>([]);

  useEffect(() => {
    const socket = io(
      process.env.REACT_APP_SOCKET_URL || "http://localhost:3000",
      {
        auth: {
          token,
        },
      }
    );

    socket.on("car", addCar);
    socket.on("fragment", updateFragment);

    return () => {
      socket.off("car", addCar);
      socket.off("fragment", updateFragment);
    };
  }, []);

  useEffect(() => {
    setTitle();
  }, []);

  const updateCar = (car: CarDto, fragment: FragmentFromSocketDto) => {
    for (const key in fragment.data) {
      if (!fragment.data[key]) continue;

      // Add key and value
      // @ts-ignore
      car[key] = fragment.data[key];
    }

    if (!car.fragments) {
      car.fragments = [];
    }

    car.fragments = [...car.fragments, fragment];

    return { ...car };
  };

  const updateFragment = (fragment: FragmentFromSocketDto) => {
    if (!fragment) return;

    setCars((previous: CarDto[]) => {
      return previous.map((car: CarDto) => {
        if (car.externalCarId === fragment.data.externalCarId) {
          return updateCar(car, fragment);
        }
        return car;
      });
    });
  };

  const setTitle = () => {
    const env = process.env.NODE_ENV;

    const envShort = env?.slice(0, 3).toUpperCase();
    if (!envShort) {
      document.title = defaultTitle.current;
      return;
    }

    if (envShort === "PRO") {
      document.title = `${envShort}D`;
      return;
    }
    document.title = envShort;
  };

  function addCar(socketCar: CarFromSocketDto) {
    setCars((previousCars) => {
      const car = CarFactory.createCar(socketCar.data, previousCars);
      const updatedCars = car.insertCar(previousCars);
      return updatedCars;
    });
  }

  return (
    <div className="App">
      <Title title="Hauptseite" />
      <Statistic />

      {cars.map((car: CarDto) => (
        <CarTile key={car.uuid} car={car} isDuplicate={car.isDuplicate} />
      ))}
      <Navbar />
    </div>
  );
}
