import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import "./Statistic.css";
import Countdown from "./Countdown";
import { v4 as uuid } from "uuid";
import { io } from "socket.io-client";
import { useAuth } from "./provider/AuthContext";
import { useNavigate } from "react-router-dom";
import EmailNotificationToggle from "./EmailNotificationToogle";

// environvent
const ENV = process.env.NODE_ENV;

const CENTRAL_API =
  process.env.REACT_APP_CENTRAL_API || "http://localhost:3000/api";

const LOGGER_BASE_URL =
  process.env.REACT_APP_LOGGER_BASE_URL || "http://localhost:3000";

const HEALT_CHECK_BASE_URL =
  process.env.REACT_APP_HEALT_CHECK_BASE_URL || "http://localhost:3011";

const API = process.env.REACT_APP_CENTRAL_API || "http://localhost:3000";

function Statistic() {
  const updateEvery10Seconds = 10000;
  const { setToken } = useAuth();

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [connectedClientsCount, setConnectedClientsCount] = useState<number>(0);
  const [counOfParsedCar, setCountOfParsedCar] = useState<number>(0);
  const [counOfParsedCarsToday, setCountOfParsedCarsToday] =
    useState<number>(0);
  const [counOfErrors, setCounOfErrors] = useState<number>(0);
  const [counOfErrorsToday, setCounOfErrorsToday] = useState<number>(0);
  const [percentageOfErrorsToday, setPercentageOfErrorsToday] =
    useState<number>(0);
  const [healthCheck, setHealthCheck] = useState();
  const [memory, setMemory] = useState<any>(null);

  const [memoryUsagePercentage, setMemoryUsagePercentage] = useState<number>(0);
  const [diskUsagePercentage, setDiskUsagePercentage] = useState<number>(0);
  const [failedParseRequests, setFailedParseRequests] = useState<number>(0);

  const { token, refreshToken } = useAuth();
  const navigate = useNavigate();

  const [initialState, setInitialState] = useState(false);

  useEffect(() => {
    const socket = io(
      process.env.REACT_APP_SOCKET_URL || "http://localhost:3000",
      {
        auth: {
          token,
        },
      }
    );

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connectedClients", updateConnectedClientsCount);

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connectedClients", updateConnectedClientsCount);
    };
  }, []);

  function onConnect() {
    setIsConnected(true);
  }

  function onDisconnect() {
    setIsConnected(false);
  }

  function updateConnectedClientsCount(count: number) {
    setConnectedClientsCount(count);
  }

  useEffect(() => {
    readCountOfErrors();
    checkHealth();
    checkMemory();
    getMemoryUsage();
    getDiskUsage();
    getFailedParseRequestsMetrics();
  }, []);

  useEffect(() => {
    if (ENV === "development") return;

    const interval = setInterval(() => {
      readCountOfErrors();
      checkHealth();
      checkMemory();
      getMemoryUsage();
      getDiskUsage();
      getFailedParseRequestsMetrics();
    }, updateEvery10Seconds);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Fetch the user's initial notification preference from the backend
    async function fetchInitialPreference() {
      const URL = `${CENTRAL_API}/email/status-search-url-notification`;
      try {
        const response = await axios.get(URL);
        setInitialState(response.data.isEnabled);
      } catch (error) {
        console.error("Failed to fetch initial notification preference", error);
      }
    }

    fetchInitialPreference();
  }, []);

  function checkHealth() {
    axios
      .get(`${HEALT_CHECK_BASE_URL}/health`)
      .then((response) => {
        setHealthCheck(response?.data);
      })
      .catch((error) => {
        if (!error?.response) {
          return;
        }
        setHealthCheck(error.response.data);
      });
  }

  function checkMemory() {
    axios
      .get(`${HEALT_CHECK_BASE_URL}/health/memory`)
      .then((response) => {
        setMemory(response?.data);
      })
      .catch((error) => {
        if (!error?.response) {
          return;
        }
        setMemory(error.response.data);
      });
  }

  const readCountOfErrors = () => {
    axios
      .get(`${LOGGER_BASE_URL}/count/cars`)
      .then((response) => {
        setCountOfParsedCar(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(`${LOGGER_BASE_URL}/count/errors`)
      .then((response) => {
        setCounOfErrors(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(`${LOGGER_BASE_URL}/count/cars-today`)
      .then((response) => {
        setCountOfParsedCarsToday(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(`${LOGGER_BASE_URL}/count/errors-today`)
      .then((response) => {
        setCounOfErrorsToday(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const readPercentageOfErrorsToday = () => {
    const totalCountToday = counOfParsedCarsToday + counOfErrorsToday;

    const errorPercentage = (counOfErrorsToday / totalCountToday) * 100;
    const errorPercentageFixed = errorPercentage.toFixed(0);
    return Number(errorPercentageFixed);
  };

  useEffect(() => {
    setPercentageOfErrorsToday(readPercentageOfErrorsToday());
  }, [counOfParsedCarsToday, counOfErrorsToday]);

  function createHealthStatus(healthCheck: any): React.ReactNode {
    if (!healthCheck) {
      return;
    }

    const details = healthCheck.details;

    if (!details) {
      return;
    }

    const services = Object.keys(details);

    return (
      <div>
        {services.map((service) => {
          const { status } = details[service];
          const icon = status === "up" ? "✅" : "❌";
          return (
            <div key={uuid()} className="statistic__text">
              {icon} {service}
            </div>
          );
        })}
      </div>
    );
  }

  const translate = (text: string) => {
    if (text === "storage") {
      return "Speicherplatz";
    }

    if (text === "memory_heap") {
      return "Arbeitsspeicher";
    }

    return text;
  };

  function createMemoryStatus(healthCheck: any): React.ReactNode {
    if (!memory) {
      return;
    }

    const details = memory.details;

    if (!details) {
      return;
    }

    const memories = Object.keys(details);

    return (
      <div>
        {memories.map((memory) => {
          const { status } = details[memory];
          const icon = status === "up" ? "✅" : "❌";
          return (
            <div key={uuid()} className="statistic__text">
              {icon} {translate(memory)} {getPercantage(memory)}
            </div>
          );
        })}
      </div>
    );
  }

  const getPercantage = (memory: string) => {
    if (memory === "storage") {
      return `${diskUsagePercentage}%`;
    }

    if (memory === "memory_heap") {
      return `${memoryUsagePercentage}%`;
    }

    return "";
  };

  function getMemoryUsage() {
    axios
      .get(`${HEALT_CHECK_BASE_URL}/memory-usage`)
      .then((response) => {
        const { memoryUsagePercent } = response.data;

        setMemoryUsagePercentage(memoryUsagePercent);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getDiskUsage() {
    axios
      .get(`${HEALT_CHECK_BASE_URL}/disk-usage`)
      .then((response) => {
        const { diskUsagePercent } = response.data;

        setDiskUsagePercentage(diskUsagePercent);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getFailedParseRequestsMetrics() {
    // response example { failedParseRequests: 10 }
    axios
      .get(`${CENTRAL_API}/metrics/failed-parse-requests`)
      .then((response) => {
        const { failedParseRequests } = response.data;
        setFailedParseRequests(failedParseRequests);
      })
      .catch((error) => {
        console.error("Failed to fetch failed parse requests", error);
      });
    return "";
  }

  const handleLogout = async () => {
    setToken();
    const response = await axios.post(`${API}/auth/logout`, {
      refresh_token: refreshToken,
    });
    navigate("/", { replace: true });
  };

  return (
    <div className="statistic__row">
      <div className="statistic">
        <div className="main__status__text">
          {isConnected ? "🟢 Connected " : "🔴 Disconnected "}
          ...... 👨🏻‍💻 {connectedClientsCount}
        </div>

        <div className="statistic__text">
          🚙 Insgesamt Autos: {counOfParsedCar + counOfErrors}
        </div>

        <div className="statistic__text">
          🚙 Insgesamt Autos heute: {counOfParsedCarsToday + counOfErrorsToday}
        </div>

        <div className="statistic__text">
          🚧 Fehler heute: {percentageOfErrorsToday}%
        </div>
      </div>

      <div className="statistic">
        <div className="statistic__text" style={{ fontWeight: 600 }}>
          ⚙️ Service:
        </div>
        {createHealthStatus(healthCheck)}
      </div>

      <div className="statistic">
        <div className="statistic__text" style={{ fontWeight: 600 }}>
          📈 Speicher:
        </div>
        {createMemoryStatus(memory)}
      </div>

      <div className="statistic">
        <EmailNotificationToggle initialState={initialState} />
        <div>🚨 Fehler beim parsen mobile.de: {failedParseRequests}</div>
      </div>

      <div className="statistic__right">
        <button onClick={handleLogout}>Auslogen</button>
      </div>
    </div>
  );
}

export default Statistic;
